// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0zLZN{position:relative;display:flex;overflow:hidden;min-height:96px;flex-flow:column;align-items:center;padding:0 8px 24px;background:#e2e2e2}@media screen and (min-width:400px){._0zLZN{padding:0 16px 24px}}._0zLZN img{-o-object-fit:cover;object-fit:cover}.OMa8u{color:#989898;font-size:12px;line-height:24px}.QlV-C{top:32px;left:0;width:100%;height:100%;padding:0 20px 32px;background:#e2e2e2;color:#f02d37}.QlV-C,.tF5To{position:absolute}.tF5To{top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardAdfoxMobile": "_0zLZN",
	"title": "OMa8u",
	"holder": "QlV-C",
	"loader": "tF5To"
};
module.exports = ___CSS_LOADER_EXPORT___;
